export function getUserInitials (name: string) {
  const upperName = name.toUpperCase()
  const names = upperName.split(' ').filter(n => n)

  if (names.length > 1) {
    return names[0].slice(0, 1) + names[1].slice(0, 1)
  }

  return upperName.slice(0, 2)
}
