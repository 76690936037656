<template>
  <q-layout
    view="lHh lpR lFr"
    class="bg-primary-lightest"
  >
    <q-header
      reveal
      bordered
      class="q-py-sm bg-white text-black"
    >
      <q-toolbar>
        <q-btn
          flat
          round
          :icon="menuIcon"
          @click="toggleNavigationVisibility"
        />

        <q-toolbar-title v-if="titleState">
          {{ titleState }}
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="isNavigationOpened"
      side="left"
      show-if-above
      class="text-white bg-primary-gradient"
    >
      <q-scroll-area
        class="window-height q-pa-md"
        content-style="display: flex; flex-direction: column;"
      >
        <div class="row items-center q-mt-sm q-gutter-x-md">
          <img
            src="/images/logo-light.png"
            class="logo"
          />

          <span class="text-h5">
            Live Display
          </span>
        </div>

        <q-list class="col q-mt-lg q-gutter-y-sm">
          <q-item
            clickable
            v-ripple
            :to="{ name: 'playlists' }"
            :disable="!isAuthenticated"
            active-class="text-primary bg-white rounded-borders"
            @click="setTitle('Listes de lecture')"
          >
            <q-item-section avatar>
              <icon
                name="ph:monitor-play-duotone"
                size="1.8rem"
              />
            </q-item-section>

            <q-item-section>
              Listes de lecture
            </q-item-section>

            <q-tooltip
              v-if="!isAuthenticated"
              anchor="bottom middle"
              self="bottom middle"
            >
              Connexion requise
            </q-tooltip>
          </q-item>

          <q-item
            clickable
            v-ripple
            :to="{ name: 'screens' }"
            :disable="!isAuthenticated"
            active-class="text-primary bg-white rounded-borders"
            @click="setTitle('Écrans de diffusion')"
          >
            <q-item-section avatar>
              <icon
                name="ph:monitor-duotone"
                size="1.8rem"
              />
            </q-item-section>

            <q-item-section>
              Écrans de diffusion
            </q-item-section>

            <q-tooltip
              v-if="!isAuthenticated"
              anchor="bottom middle"
              self="bottom middle"
            >
              Connexion requise
            </q-tooltip>
          </q-item>

          <q-item
            clickable
            v-ripple
            :to="{ name: 'assets' }"
            :disable="!isAuthenticated"
            active-class="text-primary bg-white rounded-borders"
            @click="setTitle('Fichiers multimédia')"
          >
            <q-item-section avatar>
              <icon
                name="ph:images-duotone"
                size="1.8rem"
              />
            </q-item-section>

            <q-item-section>
              Fichiers multimédia
            </q-item-section>

            <q-tooltip
              v-if="!isAuthenticated"
              anchor="bottom middle"
              self="bottom middle"
            >
              Connexion requise
            </q-tooltip>
          </q-item>
        </q-list>

        <q-list class="q-gutter-y-sm q-mt-sm">
          <q-item
            v-if="isUserAdmin"
            clickable
            v-ripple
            :to="{ name: 'teams' }"
            :disable="true"
            active-class="text-primary bg-white rounded-borders"
            @click="setTitle('Équipes et utilisateurs')"
          >
            <q-item-section avatar>
              <icon
                name="ph:users-duotone"
                size="1.8rem"
              />
            </q-item-section>

            <q-item-section>
              Équipes et utilisateurs
            </q-item-section>

            <q-tooltip
              anchor="bottom middle"
              self="bottom middle"
            >
              Prochainement disponible
            </q-tooltip>
          </q-item>

          <q-item
            clickable
            v-ripple
            :to="{ name: 'support' }"
            active-class="text-primary bg-white rounded-borders"
            @click="setTitle('Aide et support')"
          >
            <q-item-section avatar>
              <icon
                name="ph:lifebuoy-duotone"
                size="1.8rem"
              />
            </q-item-section>

            <q-item-section>
              Aide et support
            </q-item-section>
          </q-item>
        </q-list>

        <div class="col-auto row items-center q-mt-md q-mb-sm q-pa-sm rounded-borders bg-highlight-light">
          <div class="initials col-auto column items-center justify-center rounded-borders bg-white text-primary">
            <span v-if="isAuthenticated">
              {{ userInitials }}
            </span>

            <q-icon
              v-else
              name="person_off"
              size="sm"
            />
          </div>

          <div
            v-if="isAuthenticated"
            class="col q-ml-md"
          >
            <div>
              {{ currentUser.initial.name }}
            </div>

            <div class="text-weight-light">
              {{ currentEnterprise.initial.name }}
            </div>
          </div>
          <div
            v-else
            class="col q-ml-xs"
          >
            <q-btn
              flat
              align="left"
              label="Se connecter"
              text-color="white"
              class="full-width"
              padding="sm md"
              @click="navigateTo('/sign-in')"
            />
          </div>

          <div
            v-if="isAuthenticated"
            class="col-auto"
          >
            <q-btn
              color="white"
              icon="more_vert"
              round
              flat
            >
              <q-menu
                anchor="top start"
                self="bottom start"
                auto-close
              >
                <q-list style="min-width: 140px">
                  <q-item
                    clickable
                    :exact="true"
                    :to="{ name: 'account' }"
                    @click="setTitle('Compte')"
                  >
                    <q-item-section>
                      Compte
                    </q-item-section>
                  </q-item>

                  <q-item
                    clickable
                    :exact="true"
                    :to="{ name: 'invoices' }"
                    @click="setTitle('Factures')"
                  >
                    <q-item-section>
                      Factures
                    </q-item-section>
                  </q-item>

                  <q-item
                    clickable
                    @click="signOut"
                  >
                    <q-item-section>
                      Se déconnecter
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </q-scroll-area>
    </q-drawer>

    <q-drawer
      v-model="isOptionsOpened"
      side="right"
      overlay
      bordered
    >
      <!-- drawer content -->
    </q-drawer>

    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
const authenticationStore = useAuthenticationStore()
const { isAuthenticated, authUser } = storeToRefs(authenticationStore)
const { signOut } = authenticationStore

const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

const enterpriseStore = useEnterpriseStore()
const { currentEnterprise } = storeToRefs(enterpriseStore)

const isNavigationOpened = ref(true)
const isOptionsOpened = ref(false)

const titleState = useState<string|null>('title', () => null)

const isUserAdmin = computed(() => {
  if (!authUser.value) {
    return false
  }

  const adminTeamId = authUser.value.claims.adminTeamId

  return typeof adminTeamId === 'string' && adminTeamId.length > 0
})

const userInitials = computed(() => {
  const currUser = currentUser.value.initial

  if (!currUser) {
    return ''
  }

  return getUserInitials(currUser.name)
})

const menuIcon = computed(() => {
  return isNavigationOpened.value ? 'menu_open' : 'menu'
})

function toggleNavigationVisibility (): void {
  isNavigationOpened.value = !isNavigationOpened.value
}

function toggleOptionsVisibility (): void {
  isOptionsOpened.value = !isOptionsOpened.value
}

function setTitle (title: string): void {
  titleState.value = title
}
</script>

<style scoped>
.logo {
  width: 42px;
  height: auto;
}

.initials {
  width: 42px;
  height: 42px;
}
</style>
